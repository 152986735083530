<div class="modal-content">
    <div class="modal-header">
        <button type="button" class="btn-close" aria-label="Close"
            (click)="activeModal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <div class="d-flex justify-content-center mb-4"><img [src]="'/assets/system/logo_sidebar.png'" width="200px">
        </div>
        <h6 class="d-flex justify-content-center mb-5">Puedes ver las especificaciones de cada plan&nbsp;<a href="https://lubrikuv.com#planes"> en nuestra página web</a>.</h6>
        <h6 class="d-flex justify-content-center mb-3">Seleccione un plan para probar.</h6>
        <div class="d-flex justify-content-evenly mb-5">
            <div class="card mensual" (click)="seleccionarPlan(1)">
                <div class="d-flex flex-column justify-content-between h-100 align-items-center">
                    <div class="title">FILTRO X</div>
                </div>
            </div>
            <div class="card mensual" (click)="seleccionarPlan(3)">
                <div class="title">ACELERA+</div>
            </div>
            <div class="card mensual" (click)="seleccionarPlan(5)">
                <div class="title">TURBO</div>
            </div>
        </div>
        <hr>
        <br>
        <h6 class="d-flex justify-content-center mb-3">Si desea suscribirse a un plan, puede pasar a Premium.</h6>
        <div class="w-100 d-flex justify-content-center my-2">
            <div class="w-100 btn btn-primary text-center" (click)="pagarPlan()">Pasar a Premium</div>
        </div>
    </div>
    <div class="modal-footer">
    </div>
</div>