<div id="project-base">
  <div id="project-sidebar" class="reportes-menu-tutorial" *ngIf="showSidebarAndHeader">
    <kuv-sidebar [logo]="'/assets/system/logo_sidebar.png'" [routes]="sesion.routes"></kuv-sidebar>
  </div>
  <div id="banner-vencimiento" class="text-white text-center d-flex justify-content-center align-items-center" *ngIf="sesion.user.cuenta_prueba" (click)="cambiarPrueba()">
    <i class="fas fa-info-circle me-2"></i>
    <div>Estás en tu periodo de prueba - <b>PLAN {{getPlan()}}</b> - Pulse <b>aquí</b> para probar otro plan.</div>
  </div>
  <div id="banner-vencimiento" class="text-white text-center d-flex justify-content-center align-items-center"
    *ngIf="!sesion.user.cuenta_prueba && sesion.user.vencimiento_suscripcion < fechaAlertaVencimientoSuscripcion" (click)="soporteLubriKuv()">
    <i class="fas fa-info-circle me-2"></i>
    <div *ngIf="obtenerDiasHastaVencimiento() != null && obtenerDiasHastaVencimiento() > 0 && obtenerDiasHastaVencimiento() < 8">
      Tu suscripción vence en {{diasHastaVencimiento}} días. No olvides realizar el pago.
    </div>
    <div *ngIf="diasHastaVencimiento == 0">
      Tu suscripción está a punto de vencer. No olvides realizar el pago.
    </div>
    <div *ngIf="obtenerDiasHastaVencimiento() >= DIAS_TRAS_VENCIMIENTO_LIMITE && obtenerDiasHastaVencimiento() < 0">
      Tu suscripción ha vencido. DEBE REALIZAR SU PAGO INMEDIATAMENTE O SU CUENTA SERÁ BLOQUEADA PRONTO.
    </div>
    <div *ngIf="obtenerDiasHastaVencimiento() < DIAS_TRAS_VENCIMIENTO_LIMITE">
      Tu suscripción ha vencido. Para utilizar el sistema realiza el pago.
    </div>
  </div>
  <div id="project-header" *ngIf="showSidebarAndHeader">
    <ng-template #popTitle><label style="font-weight: 600;">Notificaciones</label></ng-template>
    <ng-template #popContent>
      <div class="d-flex flex-column gap-2 p-3 custom-sidebar" style="max-height: 500px; overflow: auto;">
        <div *ngIf="stockCriticos.length == 0">
          No existen notificaciones.
        </div>
        <div class="card p-1" *ngFor="let s of stockCriticos;" (click)="verStockCritico()">
          <div class="tag p-1 warning d-flex align-items-center gap-2" *ngIf="s.cantidad != 0">
            <i class="fas fa-exclamation-circle"></i>
            <div>El producto <b>{{s.producto.nombre}}</b> tiene {{s.cantidad}} unidad(es) de un mínimo de {{s.producto.stock_minimo}}.</div>
          </div>
          <div class="tag p-1 danger d-flex align-items-center gap-2" *ngIf="s.cantidad == 0">
            <i class="fas fa-times-circle"></i>
            <div>El producto <b>{{s.producto.nombre}}</b> se ha quedado sin stock.</div>
          </div>
        </div>
        <a class="btn-link" style="cursor: pointer;" (click)="verStockCritico()">Ver detalles...</a>
      </div>
    </ng-template>

    <div class="busqueda d-inline mx-2"><app-busqueda></app-busqueda></div>

    <button class="btn btn-primary mx-2" (click)="crearOT()"><i class="fas fa-add"></i> <span class="d-none d-sm-inline ms-2">Crear OT</span></button>

    <button class="btn btn-primary mx-2" (click)="soporteLubriKuv()"><i class="fa-regular fa-circle-question"></i> <span class="d-none d-sm-inline ms-2">Ayuda</span></button>

    <div *ngIf="sesion.sucursales.length > 0" class="mx-3 d-none d-md-flex">
      <select class="form-select" id="sucursal" [(ngModel)]="sesion.sucursalIndex" (change)="changeScope($event)">
        <ng-container *ngFor="let s of sesion.sucursales; let i = index">
          <option [value]="i">{{s.nombre}}</option>
        </ng-container>
      </select>
    </div>

    <!--button *ngIf="alertas.length != 0" type="button" class="btn btn-kuv tool-btn position-relative" placement="bottom-right"  (click)="showModalAlertasSII()">
      <i class="fas fa-info"></i>
    </button-->

    <button type="button" class="btn btn-kuv tool-btn position-relative" placement="bottom-right" [ngbPopover]="popContent"
      [popoverTitle]="popTitle" popoverClass="custom-popover">
      <i class="fas fa-bell"></i>
      <span class="position-absolute top-50 start-100 translate-middle badge rounded-pill text-bg-danger" *ngIf="stockCriticos.length != 0">{{stockCriticos.length}}</span>
    </button>

    <div ngbDropdown class="d-inline-block mx-2">
      <button class="btn btn-kuv" id="dropdownBasic1" ngbDropdownToggle>
        <i class="fas fa-user"></i><span class="mx-2 d-none d-sm-inline"> Mi Cuenta</span>
      </button>

      <button class="btn btn-primary ms-2" (click)="realizarPagoPremium()" *ngIf="sesion.user.cuenta_prueba && habilitarSuscripciones && sesion.enableSubscriptionUI"><i class="fas fa-rocket me-2"></i> Pasar a Premium</button>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
        <button ngbDropdownItem (click)="verPerfil()">
          <div class="d-flex gap-2 my-1">
            <span class="mr-4">
              <i class="fas fa-user-alt"></i>
            </span>
            <div>Ver Perfil</div>
          </div>
        </button>
        <button *ngIf="sesion.enableSubscriptionUI" ngbDropdownItem (click)="verSuscripcion()">
          <div class="d-flex gap-2 my-1">
            <span class="mr-4">
              <i class="fas fa-circle-info"></i>
            </span>
            <div>Ver Mi Suscripción</div>
          </div>
        </button>
        <button ngbDropdownItem (click)="changePass()">
          <div class="d-flex gap-2 my-1">
            <span class="mr-4">
              <i class="fas fa-key"></i>
            </span>
            <div>Cambiar Contraseña</div>
          </div>
        </button>
        <button ngbDropdownItem class="d-flex gap-2" (click)="mostrarVersion()">
          <span class="mr-4">
            <i class="fas fa-info-circle"></i>
          </span>
          <div>Versión 1.4.1</div>
        </button>
        <button ngbDropdownItem (click)="logout()">
          <div class="d-flex gap-2 my-1">
            <span class="mr-4">
              <i class="fas fa-sign-out-alt"></i>
            </span>
            <div>Cerrar Sesión</div>
          </div>
        </button>
      </div>
    </div>

    <!-- <button type="button" class="d-none d-md-block btn btn-kuv tool-btn position-relative" (click)="enviarSugerencia()">
      <i class="fas fa-comment-dots"></i>
    </button> -->

  </div>
  <div id="project-container" [ngClass]="{'no-sidebar': !showSidebarAndHeader}">
    <kuv-loading></kuv-loading>
    <kuv-alert></kuv-alert>
    <router-outlet></router-outlet>
  </div>
</div>