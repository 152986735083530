import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DateService } from '../date/date.service';
import { FormatterService } from '../formatter/formatter.service';
import { SesionService } from '../sesion/sesion.service';
import { AlertService, LoadingService } from 'ngx-kuv-tools';
import { ModalDeleteComponent } from '../modal-delete/modal-delete.component';
import { SuscripcionesService } from 'src/app/components/suscripciones/suscripciones.service';
import { SuscripcionesInformacionPlanesComponent } from 'src/app/components/suscripciones/suscripciones-informacion-planes/suscripciones-informacion-planes.component';
import { SentryService } from '../sentry/sentry.service';

@Component({
  selector: 'app-test-plan-selector',
  templateUrl: './test-plan-selector.component.html',
  styleUrls: ['./test-plan-selector.component.scss']
})
export class TestPlanSelectorComponent implements OnInit {


  constructor(
    public activeModal: NgbActiveModal,
    public service: SesionService,
    public formatter: FormatterService,
    public date: DateService,
    private modalService: NgbModal,
    private loading: LoadingService,
    private alerts: AlertService,
    private suscripcionesService: SuscripcionesService,
    private sentryService: SentryService,
  ) { }

  ngOnInit() { }

  seleccionarPlan(tipo: number): void {
    this.loading.show()
    this.service.cambiarPlanPrueba(tipo).subscribe({
      next: (res: any) => {
        this.loading.hide();
        location.reload();

      }, error: (err: any) => {
        this.loading.hide();
        if (err.response) this.alerts.addAlert(err.response, 'danger');
      }
    });
  }

  pagarPlan(): void {
    this.realizarPagoPremium();
  }

  async realizarPagoPremium() {
    this.loading.show();
    this.suscripcionesService.planActual().subscribe({
      next: (res: any) => {
        this.loading.hide();
        const modalRef = this.modalService.open(SuscripcionesInformacionPlanesComponent, { size: 'xl', scrollable: true, centered: true });
        modalRef.componentInstance.suscripcionPrevia = res.suscripcion;
        modalRef.componentInstance.suscripcionVPPrevia = res.suscripcionVP;
        modalRef.result.then((result: any) => { }, (reason: any) => { });
      },
      error: (err: any) => {
        console.error(err);
        this.loading.hide();
        this.alerts.addAlert("Error al obtener la información.", "danger");
        this.sentryService.captureException(err.error);
      }
    });
  }
}
